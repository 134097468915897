.page-label {
  display: none;
  left: 0;
  position: fixed;
  top: 50%;
  transform: translate(calc(-50% + 24px), -50%) rotate(-90deg);
  @media(min-width: $tablet-width) {
    display: block;
  }
  h1 {
    position: relative;
    white-space: nowrap;
    &:before {
      background: $almost-black;
      content: '';
      height: 1px;
      left: calc(-50vh - 8px);
      position: absolute;
      top: 50%;
      width: 50vh;
    }
  }
}
