.content {
  margin: $content-top $content-right-mobile spacing-multiplier(10) $content-left-mobile;
  max-width: $content-width;
  @media(min-width: $tablet-width) {
    margin: $content-top $content-right-tablet spacing-multiplier(10) $content-left-tablet;
  }
  @media(min-width: $desktop-width) {
    margin: $content-top $content-right-desktop spacing-multiplier(10) $content-left-desktop;
  }
  @media(min-width: $desktop-large-width) {
    margin: $content-top $content-right-desktop spacing-multiplier(10) $content-left-wide-desktop;
  }
}

.content.lines {
  margin: 0 $content-right-mobile 0 $content-left-mobile;
  min-height: 100vh;
  max-width: $content-width;
  padding: $content-top 0 0;
  position: relative;
  @media(min-width: $tablet-width) {
    margin: 0 $content-right-tablet 0 $content-left-tablet;
  }
  @media(min-width: $desktop-width) {
    border-left: 1px solid $almost-black;
    border-right: 1px solid $almost-black;
    margin: 0 $content-right-desktop 0 $content-left-desktop;
  }
  @media(min-width: $desktop-large-width) {
    margin: 0 $content-right-desktop 0 $content-left-wide-desktop;
  }
  .content-lines__content {
    border: 1px solid $almost-black;
    @media(min-width: $desktop-width) {
      border-left: 0;
      border-right: 0;
    }
    > *:not(.emote-grid):not(.commission-item) {
      margin-left: spacing-multiplier(2);
      margin-right: spacing-multiplier(2);
      &:first-child {
        margin-top: spacing-multiplier(3);
      }
    }
    > *:last-child:not(.emote-grid) {
      margin-bottom: spacing-multiplier(14);
    }
  }
}

.content.gallery {
  max-width: 2000px;
  padding-bottom: 0;
  @media(min-width: $desktop-width) {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-rows: 8px;
    &:before {
      background: $almost-black;
      content: '';
      height: 100%;
      left: 50%;
      position: absolute;
      top: 0;
      width: 1px;
    }
  }
}