.emote-grid {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 4px);
  &:first-child .emote-wrapper {
    margin-top: -1px;
  }
  &:last-child .emote-wrapper {
    margin-bottom: -1px;
  }
  .emote-wrapper {
    border: 1px solid $almost-black;
    flex-basis: calc(100% /3);
    margin: 0 -1px spacing-multiplier(3) 0;
    padding: spacing-multiplier(1);
    &:nth-child(3n-2) {
      margin-left: -1px;
    }
  }
}