a.btn {
	@include cutive-mono;
	background-color: $pink;
	display: block;
	letter-spacing: 0.1rem;
	margin-right: spacing-multiplier(3);
	padding: spacing-multiplier(0.4) spacing-multiplier(2) spacing-multiplier(0.5);
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	width: fit-content;
	z-index: 1;
	&.yellow {
		background-color: $yellow;
	}
	&.outline {
		background-color: transparent;
		border: 1px solid $almost-black;
	}
	&.commission {
		background-color: transparent;
		border: 1px solid $almost-black;
		display: flex;
		justify-content: space-between;
		margin-bottom: -1px;
		margin-right: -16px;
		width: auto;
	}
	&:focus {
		outline-color: $almost-black;
		outline-style: solid;
		outline-width: 1px;
		outline-offset: 4px;
	}
	&:before {
		background-color: $almost-black;
		height: 100%;
		transform: scaleY(0);
		transform-origin: bottom;
		z-index: -1;
	}
	&:hover {
		color: $almost-white;
		&:before {
			transform: scaleY(1);
		}
	}
	span {
		@include cutive-mono;
		pointer-events: none;
		transition: color 300ms ease-in-out;
		&:before {
			background: #fff;
			position: absolute;
			content: "";
	 }
	}
}