.emote-sizes {
  align-items: flex-end;
  display: flex;
  .emote-sizes__single {
    margin-right: spacing-multiplier(3);
    &:nth-child(1) {
      .box {
        height: 112px;
        width: 112px;
      }
    }
    &:nth-child(2) {
      .box {
        height: 56px;
        width: 56px;
      }
    }
    &:nth-child(3) {
      .box {
        height: 28px;
        width: 28px;
      }
    }
    .box {
      border: 1px solid $almost-black;
    }
    p.small {
      margin-bottom: 0;
    }
  }
}