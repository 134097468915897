nav.navigation-wrapper .logo {
  left: spacing-multiplier(-3);
  position: absolute;
  top: spacing-multiplier(-5);
  width: 180px;
  z-index: 1001;
  @media (min-width: $mobile-width) {
    top: spacing-multiplier(-7);
    width: 220px;
  }
  @media (min-width: $desktop-width) {
    left: spacing-multiplier(-5);
    position: fixed;
    top: spacing-multiplier(-8);
    width: 280px;
  }
  a {
    &:hover {
      svg g#logo_svg__bolt_top,
      svg g#logo_svg__bolt_bottom {
        animation: delayedFlash 600ms 300ms step-start;
      }
    }
    svg {
      height: auto;
      width: 100%;
    }
  }
}

@keyframes delayedFlash {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

nav.navigation-wrapper.menu-close {
  .menu-icon svg {
    &:hover path {
      &:first-child {
        transform: translateY(5px);
      }
      &:last-child {
        transform: translateY(5px) rotate(180deg);
      }
    }
    path {
      &:first-child {
        transform: translate(-3px, 4px) rotate(45deg);
      }
      &:last-child {
        transform: translate(4px, 4px) rotate(135deg);
      }
    }
  }
  .navigation-container {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }
}

nav.navigation-wrapper .menu-icon {
  cursor: pointer;
  position: absolute;
  top: spacing-multiplier(3);
  right: spacing-multiplier(2);
  z-index: 1001;
  @media (min-width: $tablet-width) {
    right: spacing-multiplier(3);
  }
  @media (min-width: $desktop-width) {
    position: fixed;
  }
  svg {
    height: 41px;
    width: 41px;
    path {
      transform-origin: center;
      transition: transform 300ms ease-in-out;
    }
    g {
      stroke-width: 2px;
    }
    &:hover {
      path {
        &:first-child {
          transform: translateY(5px);
        }
        &:last-child {
          transform: translateX(5px) rotate(90deg);
        }
      }
    }
  }
}

nav.navigation-wrapper .navigation-container {
  align-items: center;
  background-color: $pink;
  display: flex;
  height: 100vh;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: all 500ms ease-in-out;
  top: 0;
  visibility: hidden;
  width: 100vw;
  z-index: 1000;
  @media (min-width: $desktop-width) {
    position: fixed;
  }
  ul.navigation {
    margin: 50px $content-right-mobile 0 $content-left-mobile;
    padding: 0;
    @media (min-width: $tablet-width) {
      margin: 100px $content-right-tablet 0 $content-left-tablet;
    }
    @media (min-width: $desktop-width) {
      margin: 0 $content-right-desktop 0 $content-left-desktop;
    }
    @media (min-width: $desktop-large-width) {
      margin: 0 $content-right-desktop 0 $content-left-wide-desktop;
    }
    li.nav-link {
      list-style: none;
      margin: 0;
      padding: 0;
      &::marker {
        content: none;
      }
      a {
        @include fluid-type($min_viewport, $max_viewport, 40px, 80px);
        margin-right: 0;
        border: none;
        margin-bottom: spacing-multiplier(0);
        padding: 0;
        &:before {
          display: none;
        }
        &:hover {
          color: $almost-black;
        }
        // width: auto;
        // padding: spacing-multiplier(0.35) spacing-multiplier(4) spacing-multiplier(0.5);

        // old nav style
        // display: inline-block;
        // overflow: hidden;
        // padding-left: 40px + spacing-multiplier(1);
        // padding-right: spacing-multiplier(1);
        // transition: color 300ms ease-in-out;
        // &:before {
        //   bottom: auto;
        //   transform: translateX(calc(-100% + 40px));
        //   width: 100%;
        //   z-index: -1;
        // }
        // &:hover {
        //   color: $almost-white;
        //   &:before {
        //     transform: translateX(0) scaleY(40);
        //   }
        // }
      }
    }
  }
}
