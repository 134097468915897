.home {
  svg#cat {
    height: auto;
    left: 50%;
    max-height: 90vh;
    max-width: 90vw;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    @media(min-width: $mobile-width) {
      max-height: 70vh;
      max-width: 70vw;
    }
    g#cat_svg__shading_one, 
    g#cat_svg__body_one,
    path#cat_svg__body_one_colour {
      animation: catAnimation 600ms step-start infinite;
    }
    g#cat_svg__shading_two, 
    g#cat_svg__body_two,
    path#cat_svg__body_two_colour {
      animation: catAnimationTwo 600ms step-start infinite;
      opacity: 0;
    }
    g#cat_svg__shading_three, 
    g#cat_svg__body_three,
    path#cat_svg__body_three_colour {
      animation: catAnimationThree 600ms step-start infinite;
      opacity: 0;
    }
    path#cat_svg__light {
      animation: lightFlicker 5s step-start infinite;
    }
  }
  svg#symbols {
    height: 104vh;
    // max-width: 1630px;
    right: 10%;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    z-index: -1;
    @media(min-width: $desktop-width) {
      height: 104vh;
      right: 50%;
      top: 50%;
      transform: translate(50%, -50%);
    }
    g g {
      transform-origin: center;
      transform-box: fill-box;
      animation: iconSpin 2s linear infinite;
      &:nth-child(2n) {
        animation: iconSpin 2s 1s linear infinite;
      }
    }
  }
}


// Fix broken cat animation for now
@supports (-moz-appearance: none) {
  .home svg#cat {
    g#cat_svg__shading_one, 
    g#cat_svg__body_one,
    path#cat_svg__body_one_colour,
    g#cat_svg__shading_two, 
    g#cat_svg__body_two,
    path#cat_svg__body_two_colour,
    g#cat_svg__shading_three, 
    g#cat_svg__body_three,
    path#cat_svg__body_three_colour {
      animation: none;
    }
  }
}

@keyframes lightFlicker {
  0%, 78%, 82%, 86%, 93% {
    opacity: 1;
  }
  80%, 84%, 92% {
    opacity: 0;
  }
}

@keyframes catAnimation {
  0%, 83.33%, 100% {
    opacity: 1;
  }
  16.66%, 66.66% {
    opacity: 0;
  }
}

@keyframes catAnimationTwo {
  0%, 33.33%, 50%, 83.33%, 100% {
    opacity: 0;
  }
  16.66%, 66.66% {
    opacity: 1;
  }
}

@keyframes catAnimationThree {
  0%, 16.66%, 66.66%, 100% {
    opacity: 0;
  }
  33.33%, 50% {
    opacity: 1;
  }
}

@keyframes lightAnimationTwo {
  0%, 25% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
}

@keyframes iconSpin {
  0%, 30% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(10deg);
  }
  20% {
    transform: rotate(-10deg);
  }
}
